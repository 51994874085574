/** @jsx jsx */
import * as React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import styled from '@emotion/styled';
import { FormattedMessage, Link, injectIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import { Themed, jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Alerts from '../components/Alerts';
import HomepageSection from '../components/HomepageSection';

const ProductListing = loadable(() => import('../components/ProductListing'));

const MainSection = styled.section`
  padding: 0 5rem;
  margin: 4.8rem 0;

  @media screen and (max-width: 400px) {
    padding: 0rem 1.2rem;
    margin: 3.8rem 0;
  }
`;

const Home = ({ data, intl }) => {
  const title = intl.formatMessage({ id: 'metadata.title' });
  const description = intl.formatMessage({ id: 'metadata.description' });

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content="https://www.lockcha.com" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Alerts data={data} />
      <Link to="/products/special---tea-set/">
        <StaticImage
          src="../images/hero/teacups.jpg"
          alt="6 kinds of Chinese tea with LockCha"
          sx={{
            mt: 5,
            mb: [4, 5],
          }}
        />
      </Link>
      <HomepageSection sx={{ mt: [-1, -3] }} homepageSection={data.delight} />
      <HomepageSection homepageSection={data.bestTh} />
      <a name="products"></a>
      <MainSection>
        <Themed.h2 sx={{ textAlign: 'center', mb: 4 }}>
          <FormattedMessage id="home.featuredProducts" />
        </Themed.h2>
        <ProductListing
          products={data.products}
          shopifyProducts={data.shopifyProducts}
          featured={true}
        />
      </MainSection>
    </React.Fragment>
  );
};

export const query = graphql`
  query HomepageQuery($categoryHandle: String, $locale: String) {
    ...Alerts
    ...ProductListing
    delight: contentfulHomepageSection(slug: { eq: "delight" }, node_locale: { eq: $locale }) {
      ...HomepageSection
    }
    bestTh: contentfulHomepageSection(slug: { eq: "best-th" }, node_locale: { eq: $locale }) {
      ...HomepageSection
    }
    expArt: contentfulHomepageSection(slug: { eq: "exp-art" }, node_locale: { eq: $locale }) {
      ...HomepageSection
    }
  }
`;

Home.displayName = 'Home';

Home.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Home);
