/** @jsx jsx */
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Themed, jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { object, shape, string } from 'prop-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const MainSection = styled.section`
  padding: 0 5rem;
  margin: 4.8rem 0;

  @media screen and (max-width: 400px) {
    padding: 0rem 1.2rem;
    margin: 3.8rem 0;
  }

  & > p {
    margin: 2rem auto;
    max-width: 96rem;
  }
`;

const HomepageSection = ({ homepageSection: { content, heroImage, title }, ...restProps }) => (
  <section {...restProps}>
    <MainSection>
      <Themed.h1 sx={{ textAlign: 'center' }}>{title}</Themed.h1>
      {renderRichText(content, {})}
    </MainSection>
    <GatsbyImage
      image={heroImage.gatsbyImageData}
      alt={title}
      style={{ height: '60vh' }}
      loading="lazy"
    />
  </section>
);

HomepageSection.displayName = 'HomepageSection';
HomepageSection.propTypes = {
  homepageSection: shape({
    title: string,
    content: object,
  }),
};

export default HomepageSection;

export const query = graphql`
  fragment HomepageSection on ContentfulHomepageSection {
    title
    content {
      raw
    }
    heroImage {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
